import React, { useState } from "react";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "./Button";
import "./Subtask.css";

export const Subtask = ({
    subtask,
    index,
    toggleComplete,
    deleteSubtask,
    saveSubtask,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTask, setEditedTask] = useState(subtask.task);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editedTask) return;
        saveSubtask(subtask.id, editedTask);
        setIsEditing(false);
    };

    const handleEdit = () => {
        setEditedTask(subtask.task);
        setIsEditing(true);
    };

    return (
        <li>
            {isEditing ? (
                <form className="subtask-container" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={editedTask}
                        onChange={(event) => setEditedTask(event.target.value)}
                        placeholder="Edit subtask"
                    />
                    <Button onClick={handleSubmit} type="primary" text="Save" />
                </form>
            ) : (
                <>
                    <div className={"subtask-container"}>
                        <div
                            className={`${subtask.completed && "completed"} subtask-text`}
                            onClick={() => toggleComplete(subtask.id)}
                        >
                            {subtask.task}
                        </div>
                        <div className={"subtask-buttons-container"}>
                            <FontAwesomeIcon
                                className="edit-icon"
                                onClick={handleEdit}
                                icon={faPencil}
                            />

                            <FontAwesomeIcon
                                className="delete-icon"
                                onClick={() => deleteSubtask(subtask.id)}
                                icon={faTrash}
                            />
                        </div>
                    </div>
                </>
            )}
        </li>
    );
};
