import React, { useState } from "react";
import "./Todo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGripLines,
    faPencil,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Subtask } from "./Subtask";
import { Button } from "./Button"; // Import a specific icon
import { useRef } from "react";

export const Todo = ({
    todo,
    index,
    toggleComplete,
    deleteTodo,
    editTodo,
    subtasks,
    addSubtask,
    saveSubtask,
    deleteSubtask,
    toggleCompleteSubtask,
}) => {
    const [showInput, setShowInput] = useState(false);
    const [subtaskText, setSubtaskText] = useState("");
    const inputRef = useRef(null);

    const handleFocus = () => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    };

    const handleAddSubtask = () => {
        setShowInput(true);
        handleFocus();
    };

    const handleAddNewSubtask = () => {
        if (!subtaskText) return;
        addSubtask(todo.id, subtaskText);
        setSubtaskText("");
        handleAddSubtask();
    };

    const handleSaveSubtask = (subtaskId, value) => {
        saveSubtask(todo.id, subtaskId, value);
    };

    const handleCompleteSubtask = (subtaskId) => {
        toggleCompleteSubtask(todo.id, subtaskId);
    };

    const handleSubmitSubtask = (e) => {
        e.preventDefault();
        if (!subtaskText) return;
        addSubtask(todo.id, subtaskText);
        setSubtaskText("");
        setShowInput(false);
    };

    return (
        <li className={`todo-wrapper line-separator`}>
            <div className={"todo-container"}>
                <span className="drag-handle">
                    <FontAwesomeIcon icon={faGripLines} />
                </span>{" "}
                {/* This is the handle */}
                <div
                    className={`${todo.completed && "completed"} todo-text`}
                    onClick={() => toggleComplete(todo.id)}
                >
                    {todo.task}
                </div>
                <FontAwesomeIcon
                    className="edit-icon"
                    onClick={handleAddSubtask}
                    icon={faPlus}
                ></FontAwesomeIcon>
                <span className="edit-icon" onClick={() => editTodo(todo.id)}>
                    <FontAwesomeIcon icon={faPencil} />
                </span>
                <span
                    className="delete-icon"
                    onClick={() => deleteTodo(todo.id)}
                >
                    <FontAwesomeIcon icon={faTrash} />
                </span>
            </div>

            {showInput && (
                <form className={"new-subtask"} onSubmit={handleSubmitSubtask}>
                    <input
                        ref={inputRef}
                        type="text"
                        value={subtaskText}
                        onChange={(e) => setSubtaskText(e.target.value)}
                        placeholder="Add subtask"
                    />
                    <Button
                        type="submit"
                        status={"primary"}
                        icon={faPlus}
                    ></Button>
                    <Button
                        onClick={handleAddNewSubtask}
                        status={"primary"}
                        text={'Next'}
                    ></Button>
                </form>
            )}

            <ul className="subtasks">
                {subtasks.map((subtask, index) => (
                    <Subtask
                        key={subtask.id}
                        subtask={subtask}
                        index={index}
                        toggleComplete={handleCompleteSubtask}
                        saveSubtask={handleSaveSubtask}
                        deleteSubtask={() => deleteSubtask(todo.id, subtask.id)}
                    />
                ))}
            </ul>
        </li>
    );
};
