import React from "react";
import './TodoList.css';
import {Todo} from "./Todo";
import {EditTodo} from "./EditTodo";
import {ReactSortable} from "react-sortablejs";

export const TodoList = ({todos, setTodos, deleteTodo, editTodo, saveTodo, handleAddSubtask, handleSaveSubtask, handleDeleteSubtask, toggleCompleteSubtask}) => {
    const toggleComplete = id => {
        setTodos(todos.map(todo => todo.id === id ? {...todo, completed: !todo.completed} : todo))
    }

    return (
        <ReactSortable
            list={todos}
            setList={setTodos}
            className="steps-container"
            handle=".drag-handle"  // This ensures sorting only starts when the handle is clicked

        >
            {todos.map(
                (todo, index) => {
                    return todo.edit
                        ? <EditTodo
                            saveTodo={saveTodo}
                            task={todo}
                            key={todo.id}
                        />
                        : <Todo
                            todo={todo}
                            index={index}
                            toggleComplete={toggleComplete}
                            deleteTodo={deleteTodo}
                            editTodo={editTodo}
                            subtasks={todo.subtasks || []}
                            addSubtask={handleAddSubtask}
                            saveSubtask={handleSaveSubtask}
                            deleteSubtask={handleDeleteSubtask}
                            toggleCompleteSubtask={toggleCompleteSubtask}
                            key={todo.id}
                        />
                }
            )}
        </ReactSortable>
    )
}
