import './Button.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export const Button = ({status, type, text, icon, onClick}) => {
    return (
        <button
            className={`button-container ${status} ${type}`}
            onClick={onClick}
        >
            {text}
            {icon && <FontAwesomeIcon icon={icon} />}
        </button>
    );
}
